.vui-spinner {
  padding: 15px 2px 3px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.vui-spinner__label {
  display: flex;
  align-items: center;
}
.vui-spinner[data-label-position='right'] {
  justify-content: flex-end;
}
.vui-spinner[data-label-position='right'] .vui-spinner-container {
  margin: 0;
}
.vui-spinner[data-label-position='right'] .vui-spinner__label {
  margin-left: 1em;
}
.vui-spinner[data-label-position='bottom'] {
  flex-direction: column;
}
.vui-spinner[data-label-position='bottom'] .vui-spinner__label {
  text-align: center;
  justify-content: center;
}
.vui-spinner[data-label-position='top'] {
  flex-direction: column;
}
.vui-spinner[data-label-position='top'] .vui-spinner__label {
  order: -1;
  text-align: center;
  justify-content: center;
}
.vui-spinner[data-speed='stop'] .vui-spinner-fragment {
  animation-play-state: paused;
}
.vui-spinner[data-speed='slow'] .vui-spinner-fragment--air {
  animation-duration: 3s;
}
.vui-spinner[data-speed='slow'] .vui-spinner-fragment--land {
  animation-duration: 2.75s;
}
.vui-spinner[data-speed='slow'] .vui-spinner-fragment--sea {
  animation-duration: 2.5s;
}
.vui-spinner[data-speed='fast'] .vui-spinner-fragment--air {
  animation-duration: 1s;
}
.vui-spinner[data-speed='fast'] .vui-spinner-fragment--land {
  animation-duration: 0.75s;
}
.vui-spinner[data-speed='fast'] .vui-spinner-fragment--sea {
  animation-duration: 0.5s;
}
.vui-spinner[data-speed='superfast'] .vui-spinner-fragment--air {
  animation-duration: 0.75s;
}
.vui-spinner[data-speed='superfast'] .vui-spinner-fragment--land {
  animation-duration: 0.5s;
}
.vui-spinner[data-speed='superfast'] .vui-spinner-fragment--sea {
  animation-duration: 0.25s;
}
.vui-spinner[data-air='true'] .vui-spinner-fragment--air {
  display: block;
  animation-name: vui-spinner-animation-air;
}
.vui-spinner[data-land='true'] .vui-spinner-fragment--land {
  display: block;
  animation-name: vui-spinner-animation-land;
}
.vui-spinner[data-sea='true'] .vui-spinner-fragment--sea {
  display: block;
  animation-name: vui-spinner-animation-sea;
}

.vui-spinner-container {
  position: relative;
  margin: auto;
  width: 220px;
  height: 220px;
}

.vui-spinner-fragment {
  position: absolute;
  border-radius: 50%;
  display: none;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.vui-spinner-fragment--air {
  top: 0;
  animation-duration: 1.25s;
}

.vui-spinner-fragment--land {
  animation-duration: 1s;
}

.vui-spinner-fragment--sea {
  animation-duration: 0.75s;
}

@keyframes vui-spinner-animation-air {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes vui-spinner-animation-land {
  0% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(480deg);
  }
}
@keyframes vui-spinner-animation-sea {
  0% {
    transform: rotate(240deg);
  }
  100% {
    transform: rotate(600deg);
  }
}
