* {
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #fff;
}
*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 3px solid #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
.leaflet-container {
  height: 200px;
} */

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-form-item-label {
  line-height: 28px;
}

.leaflet-container {
  background: #AAD3DF;
}

.anticon {
  vertical-align: 2px !important;
}

.logo-sm .logo-image {
  width: 118px;
  height: auto;;
}
